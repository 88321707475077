.flickity-button {
    background-color: rgba(white, 0.4);
    color: black;
    width: 30px;
    height: 30px;

    &:hover {
        background-color: rgba(white, 0.6); } }

.slick-slider {
    .slick-prev {
        left: 5px;
        z-index: 1; }

    .slick-next {
        right: 5px; } }
