.modal {
	position: fixed;
	transform: translate(-50%, calc(-50% - 1px));
	top: 50%;
	left: 50%;
	background: rgba(black, 0.8);
	width: calc(100vw + 0px);
	height: calc(100vh + 0px);
	// display: none
	visibility: hidden;
	z-index: 102; }

.auto_modal {
	display: block; }

.modalContent {
	transition: 0.4s transform ease-in-out;
	position: fixed;
	transform: translate(-50%, 100%);
	top: 50%;
	left: 50%;
	background: $white;
	/* border: 2px solid black */
	width: 488px;
	max-width: calc(100% - 60px);
	padding: 15px;
	padding-top: 0px;
	padding-bottom: 0px;
	max-height: 90vh;
	font-family: $sans;
	text-align: center;
	overflow: hidden;

	.modalCenter {
		max-height: calc(90vh - 30px);
		width: calc(100% + 20px);
		margin-left: -10px;
		overflow-y: auto;
		overflow-y: overlay;
		transform: translate(0px, 0px); }

	.video-js .vjs-big-play-button, .vjs-control-bar {
		z-index: 100; }

	.video-js .vjs-tech {
		border-radius: 0px; }

	.heading, .modal_heading {
		padding: 0px;
		color: black;
		font-family: $sans;
		font-size: 22px;
		margin-bottom: 20px; }

	.primary, .secondary {
		background-color: $darker_grey;
		color: $white;
		border-radius: 0px;
		border: 1px solid $darker_grey;
		outline: none;
		padding: 10px;
		font-size: 22px;
		width: 512px;
		max-width: calc(100% - 30px);
		margin: 15px;
		margin-top: 0px;
		cursor: pointer;
		margin-bottom: 0px; }

	.secondary {
		background-color: white;
		color: $darker_grey; }

	.content {
		font-size: 14px;
		margin-top: 10px;
		text-align: left;
		padding: 0px 15px;

		&.bottom {
			margin-bottom: 40px; } }

	input {
		font-size: 20px;
		padding: 10px;
		border: 1px solid #c4c4c4;
		border-bottom: 1px solid $grey;
		display: inline-block;
		width: 278px;
		max-width: calc(100% - 22px);
		transition: 0.2s ease-in-out all;
		height: 23px;
		outline: none; }

	label {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px; }

	.checkbox {
		display: inline-block;
		width: 18px;
		vertical-align: middle;
		margin: 0px;
		margin-right: 5px; }

	hr {
		margin: 30px;
		border: none;
		height: 1px;
		background-color: rgba(black, 0.1); }

	.small_text {
		font-size: 15px;
		color: black;
		display: inline-block;
		cursor: default;
		margin-bottom: 0px;
		font-weight: 600; }

	input, select, textarea {
		font-size: 20px;
		padding: 10px;
		border: 1px solid #c4c4c4;
		border-bottom: 1px solid $grey;
		display: inline-block;
		width: 278px;
		max-width: calc(100% - 22px);
		transition: 0.2s ease-in-out all;
		height: 23px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-family: $sans; }

	.phone_input {
		width: 199px;
		max-width: calc(100% - 101px);
		margin-left: -1px; }

	textarea {
		height: 90px;
		margin-top: 10px;
		margin-bottom: 0px;
		outline: none; }

	select {
		font-size: 15px;
		width: 80px;
		height: 45px;
		vertical-align: bottom;
		outline: none; }

	.skill_select {
		width: 300px;
		max-width: 100%;
		font-size: 20px; }

	.update_description {
		display: inline-block;
		text-align: left; }

	.country-description {
		font-size: 18px;
		display: inline-block;
		vertical-align: middle; }

	.country-icon {
		width: 40px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px; }

	.auth_languages {
		margin-bottom: 10px;

		.language_icon {
			width: 35px;
			margin: 0px 5px;
			padding: 2px;
			border-radius: 50%;
			border: 2px solid transparent; } }

	.audio_text {
		display: inline-block;
		width: calc(100% - 70px);
		vertical-align: middle; }

	.audio {
		border: 3px solid black;
		display: inline-block;
		width: 40px;
		height: 38px;
		text-align: center;
		vertical-align: middle;
		border-radius: 50%;
		font-size: 34px;
		padding-top: 2px;
		margin-left: 20px; }

	.audio_br {
		height: 20px; }

	audio {
		outline: none; } }

.modal_x {
	position: absolute;
	width: 100%;
	text-align: right;
	margin-top: -3px;
	margin-bottom: -15px;
	font-size: 50px;
	margin-right: -10px;
	margin-left: -10px;
	cursor: pointer;
	color: black;
	// border-top-left-radius: 20px
	// border-top-right-radius: 20px
	background-color: white;
	line-height: 30px;
	// border-top: 10px solid $primary
	overflow: hidden;

	.print {
		position: absolute;
		top: -50px;
		left: 0;
		width: 1700px;
		height: auto;
		opacity: 0.05;
		z-index: unset; } }

.spacing {
	height: 40px; }

.spacing-2 {
	height: 20px; }

.modal_button {
	display: inline-block;
	width: 100%; }
