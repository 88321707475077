.files {

    .file {
        .x {
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            margin-left: 5px;
            vertical-align: sub;
            cursor: pointer;
            line-height: 16px; } } }
