.container {
    display: inline-block;
    vertical-align: top;
    position: relative;
    max-width: calc(100% - 152px);
    padding-left: 152px;

    .columns {
        height: calc(100vh - 50px);
        width: 300%;

        .column {
            width: 300px;
            height: 100%;
            display: inline-block;
            vertical-align: top;
            position: relative;

            &.prop {
                border-right: 1px solid $dark_grey;
                background-color: $white;
                padding-bottom: 10px;
 } } } }                // height: calc(100vh - 10px)
