@import "./components/_variables";
@import "./components/_scrollbar";
@import "./components/_flickity";

body {
    margin: 0px;
    overflow-y: hidden;
    overflow-x: scroll;

    .error {
        color: red; }

    @import "./components/shortlist/fixed_column/_fixed_column";
    @import "./components/shortlist/columns/_columns";
    @import "./components/shortlist/columns/paste_link/_paste_link";
    @import "./components/shortlist/columns/properties/_property";
    @import "./components/tools/loader/_loader";
    @import "./components/shortlist/_shortlist";
    @import "./components/shortlist/_notifications";
    @import "./components/tools/modal/_modal";
    @import "./components/files/_files";
    @import "./components/maps/_maps"; }
