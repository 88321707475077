.paste {
    position: absolute;
    width: calc(100% - 70px);
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    font-family: $sans;
    background-color: $grey;
    border: 2px solid $dark_grey;
    padding: 20px 20px 10px;
    border-radius: 10px;

    .header {
        font-size: 22px; }

    .content {
        font-size: 14px;
        margin: 10px 0px; }

    .input {
        font-family: $sans;
        padding: 10px;
        width: calc(100% - 22px);
        // outline: none
        border-radius: 0px;
        border: 1px solid black;
        margin-top: 15px; }

    .error {
        margin-top: 20px;
        color: $red;
        font-size: 12px; } }

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.8);

    .spinner {
        position: absolute;
        transform: translate(-50%, -50%);
        top: calc(28% - 22px);
        left: calc(50% - 22px);
        border: 3px solid black;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        border-right: 3px solid transparent;
        animation: 0.8s linear spin infinite; } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
