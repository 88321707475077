.shortlist_heading {
    height: 62px;
    position: relative;

    .header {
        font-family: $sans;
        height: 60px;
        background-color: $grey;
        position: fixed;
        border-bottom: 2px solid $dark_grey;
        width: 100vw;

        .back_arrow {
            display: inline-block;
            font-size: 30px;
            height: 30px;
            vertical-align: top;
            margin: 15px -10px 15px 5px;
            cursor: pointer; }

        .document_name {
            font-size: 20px;
            height: 26px;
            margin: 12px 0px;
            margin-left: 15px;
            border: none;
            outline: none;
            border: 2px solid $darker_grey;
            background-color: $grey;
            padding: 5px;
            border-radius: 10px;
            border-top-left-radius: 0px; }

        .owner {
            color: black;
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;

            .by {
                color: $darker_grey;
                font-weight: 400; } }

        .register_to_save {
            display: inline-block;
            font-size: 14px;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0%, -50%);

            .link {
                display: inline-block;
                text-decoration: underline;
                text-underline-offset: 4px;
                cursor: pointer; } }

        .map_icon {
            display: inline-block;
            margin-left: 10px;
            margin-top: -5px;
            font-size: 26px;
            vertical-align: middle;
            padding: 5px 5px 0px;
            border: 2px solid black;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: red; } }

        .share_icon {
            cursor: pointer;
            display: inline-block;
            font-size: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0%, -50%);

            .dropdown {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                line-height: 30px;
                height: 30px;

                select {
                    font-size: 20px;
                    width: 100px;
                    background-color: $grey;
                    border: none;
                    outline: none;
                    border-bottom: 2px solid $darker_grey;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    margin: 0px 15px 0px 5px; } } } } }
