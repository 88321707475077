::-webkit-scrollbar {
    width: 10px;
    height: 10px; }

::-webkit-scrollbar-track {
    background: $grey;
 }    // outline: 2px solid $dark_grey

::-webkit-scrollbar-thumb {
    background: $darker_grey; }
