.fixed_column {
    width: 150px;
    height: calc(100vh - 72px);
    background-color: $grey;
    border-right: 2px solid $dark_grey;
    position: fixed;
    left: 0;
    display: inline-block;
    vertical-align: top;
    padding: 0px 0px 10px;
    z-index: 2;
    overflow: hidden;
    margin-top: -1px;

    .contain {
        height: 100%;
        display: flex;
        flex-direction: column;

        .filters {
            display: flex;
            flex-direction: column;

            .filter {
                padding: 0px 10px;
                font-family: $sans;
                font-size: 14px;
                width: calc(100% - 20px);
                text-align-last: justify;
                cursor: pointer;
                color: black;
                flex: 1;
                display: flex;
                flex-direction: column;
                list-style-type: none;

                &#images {
                    flex: 3.5; }

                &#location {
                    flex: 2; }

                &#comments {
                    flex: 4; }

                .filter_inner {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    // line-height: 50px
                    border-top: 1px solid $dark_grey;
                    align-items: center;

                    .name {
                        .move_dots {
                            color: $darker_grey;
                            padding-right: 10px;
                            cursor: all-scroll; } }

                    .icon {
                        font-size: 24px;
                        display: inline-block;
                        vertical-align: middle;
                        color: $darker_grey;
                        display: flex;
                        align-items: center; } } } }

        .add {
            margin: 0px 20px;
            background-color: $dark_grey;
            border: none;
            outline: none;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            width: calc(100% - 40px);
            position: absolute;
            bottom: 20px; } } }
