.maps {
    .map_marker {
        transform: translate(-50%, -100%);
        color: black;
        font-size: 20px;
        width: 200px;
        position: relative;
        text-align: center;

        .prop {
            .icon {
                display: inline-block;
                color: #ffeb72;
                filter: drop-shadow(0px 2px 0px black) drop-shadow(0px -1px 0px black) drop-shadow(2px 0px 0px black) drop-shadow(-1px 0px 0px black); }

            .prop_box {
                display: none;
                filter: drop-shadow(0px 2px 0px black) drop-shadow(0px -1px 0px black) drop-shadow(2px 0px 0px black) drop-shadow(-1px 0px 0px black);
                text-align: left;

                .marker_box {
                    background-color: white;
                    padding: 10px;
                    border-bottom: 0px;
                    position: relative;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    cursor: pointer;

                    .image {
                        width: calc(200px - 20px);
                        border-radius: 5px; }

                    .title {
                        color: black;
                        font-size: 12px; } }

                .bottom_arrow {
                    height: 0;
                    width: 0;
                    display: inline-block;
                    border-top: 25px solid white;
                    border-left: 99px solid transparent;
                    border-right: 99px solid transparent;
                    margin-top: -1px; } }

            &:hover {
                z-index: 3; } }

        .prop:hover {
            .prop_box {
                display: block; }

            .icon {
                display: none; } } } }
