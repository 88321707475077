// COLOUR
$grey: #f0f0f0;
$dark_grey: #ddd;
$darker_grey: #b3b3b3;

$white: white;
$yellow: #fffefa;

$red: red;

// FONTS
$sans: sans-serif;
$serif: serif;
