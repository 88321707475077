.property {
    width: calc(100% - 40px);
    height: calc(100vh - 72px);
    padding: 0px 0px 0px;
    display: flex;
    flex-direction: column;
    position: relative;

    .x {
        position: absolute;
        right: -40px;
        top: 0px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 34px;
        font-weight: 600;
        cursor: pointer; }

    .sold_sign {
        position: absolute;
        z-index: 10;
        transform: translate(-50%, -50%) rotate(30deg);
        top: 50%;
        left: calc(50% + 20px);
        width: 300px; }

    .title {
        font-size: 18px;
        padding: 0px 20px;
        font-weight: 600;
        height: calc(68px - 40px);
        font-family: $sans;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 10px);
        line-height: 48px;
        flex: 1;
        cursor: pointer;
        align-items: center;
        border-bottom: 1px solid transparent; }

    .images {
        padding: 0px 20px;
        width: 100%;
        flex: 3.5;
        border-bottom: 1px solid transparent;

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;

                img {
                    height: 100%;
                    max-height: 150px; } } } }

    .price, .bedrooms, .garden, .bathrooms {
        padding: 0px 20px;
        font-family: $sans;
        font-size: 14px;
        line-height: 24px;
        height: calc(68px - 40px);
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        border-bottom: 1px solid transparent;

        .previous {
            text-decoration: line-through;
            margin-right: 10px;
            font-weight: 400;
            color: black; } }

    .garden {
        line-height: 28px;
        width: 100%; }

    .location {
        padding: 0px 20px;
        font-family: $sans;
        font-size: 14px;
        line-height: 20px;
        height: calc(98px - 20px);
        width: 100%;
        white-space: pre-line;
        flex: 2;
        display: flex;
        align-items: baseline;
        border-bottom: 1px solid transparent; }

    .comments {
        padding: 0px 20px 0px;
        width: 100%;
        overflow-y: auto;
        flex: 4;
        border-bottom: 1px solid transparent;

        .contain {
            height: calc(100% - 10px);

            .comment_input_wrap {
                background-color: white;
                position: sticky;
                top: 0px;
                margin: 0px -20px;
                padding-top: 10px;

                .comment_input {
                    border: 1px solid $darker_grey;
                    width: calc(100% - 42.5px);
                    margin: 0px 10px 0px 10px;
                    font-size: 12px;
                    background-color: $grey;
                    border-radius: 20px;
                    padding: 5px 10px; } }

            .comment {
                padding: 10px 0px;

                .top {
                    font-family: $sans;
                    font-size: 13px;

                    .name {
                        font-weight: 600;
                        margin-right: 5px;
                        text-transform: capitalize; }

                    .date {
                        color: $darker_grey; } }

                .bottom {
                    font-family: $sans;
                    font-size: 14px;
                    margin-top: 5px; } } } } }
