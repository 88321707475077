.loader {
    width: 100vw;
    height: 100vh;
    background-color: rgba(white, 0.6);
    position: fixed;
    z-index: 100;

    .icon {
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        top: 50%;
        left: 50%;

        .spinner {
            width: 70px;
            height: 70px;
            border: 5px solid black;
            border-radius: 50%;
            border-right: 5px solid transparent;
            animation: spin 0.8s linear infinite;
            margin: auto; }

        .message {
            margin-top: 20px;
            font-family: $sans;
            font-size: 16px;
            font-weight: 600; } } }

@keyframes spin {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
